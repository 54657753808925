import React from 'react'
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core'
import ProductStatus from '../productStatus.js'

const StepDisplayProducts = ({ products, setValidatedProducts, validatedProducts, currentStep, setCurrentStep }) => {
    const filtredProducts = [
        ...new Set(products.filter(({ type }) => type == 'Base unit').map(({ productName }) => productName)),
    ]

    return (
        <TableContainer style={{ maxHeight: '500px', overflowX: 'auto', display: 'flex', flexDirection: 'column' }}>
            <Box style={{ flex: '1' }}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant='h6' component='h2'>
                                    Products Name (Excel)
                                </Typography>
                            </TableCell>

                            <TableCell>
                                <Typography variant='h6' component='h2'>
                                    Status Of product in DB
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filtredProducts.map((product, index) => (
                            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component='th' scope='row' style={{ verticalAlign: 'top' }}>
                                    <Typography variant='h6' component='h2'>
                                        {product}
                                    </Typography>
                                </TableCell>

                                <TableCell component='th' scope='row' style={{ verticalAlign: 'top' }}>
                                    <ProductStatus
                                        name={product}
                                        products={products}
                                        validatedProducts={validatedProducts}
                                        setValidatedProducts={setValidatedProducts}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
            {validatedProducts && validatedProducts.length > 0 && (
                <Box display='flex' justifyContent='center' m={2}>
                    <Button
                        onClick={() => {
                            setCurrentStep(currentStep + 1)
                        }}
                        component='label'
                        color='primary'
                        variant='outlined'
                        size='large'>
                        Next
                    </Button>
                </Box>
            )}
        </TableContainer>
    )
}

export default StepDisplayProducts
