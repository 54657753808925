import React from 'react'
import { UpdateControl } from '../../assets/interfaces/controlInterface'
import '../chillersComponent/customTable/customTable.css'

function CustomTableControl(props) {
    const { SelectedPrices, onSelectedVersionUpdate } = props
    function onPriceChange(opt, event) {
        const { id } = opt

        let value = event.target.value.trim()
        if (!isNaN(value) || value === '-' || value === '.') {
            let UpdatedVersionOptions = { ...SelectedPrices }
            let OptionIndex = UpdatedVersionOptions.Options.findIndex((opt) => opt.id === id)
            UpdatedVersionOptions.Options[OptionIndex].Price = value
            onSelectedVersionUpdate(UpdatedVersionOptions)
        }
    }

    function onInputFocusOut(opt) {
        UpdateControl(opt)
    }

    return (
        <>
            <h2>List Prices</h2>
            <div className='PriceTableContainer'>
                <table className='PricesTable'>
                    <tbody>
                        {SelectedPrices.Options.map((opt) => (
                            <tr key={opt.id + 'tr'}>
                                <td className='TableRowHeader'>
                                    <span className='OptNameCellSpan'>{opt.Name}</span>
                                </td>
                                <td key={opt.id + 'td'} className={'PriceCell ' + (opt.Price ? '' : 'EmptyPriceCell')}>
                                    <input
                                        onChange={(event) => onPriceChange(opt, event)}
                                        onBlur={() => onInputFocusOut(opt)}
                                        className='PriceCellInput'
                                        value={opt.Price}
                                    />
                                    <span className='PriceCellCurrency'> €</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default CustomTableControl
