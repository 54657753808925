import React, { useState, useEffect } from 'react'
import { GetProductVersions } from '../../../assets/interfaces/productInterface'
import { GetVersion, AddVersion } from '../../../assets/interfaces/versionInterface'
import CustomTable from '../customTable/customTable'
import SidePanel from '../sidePanel/sidePanel'
import AddPriceStepForm from '../addPriceStepForm/addPriceStepForm'
import 'react-datepicker/dist/react-datepicker.css'
import './productTabs.css'

function ProductTabs(props) {
    const { Products } = props
    const [Versions, setVersions] = useState([])
    const [SelectedVersionID, setSelectedVersionID] = useState()
    const [SelectedVersion, setSelectedVersion] = useState({})
    const [OpenPriceStepPanel, setOpenPriceStepPanel] = useState(false)

    useEffect(() => {
        setSelectedVersion({})
        if (props.SelectedIndex > -1) {
            GetSelectedProductVersions()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.SelectedIndex])

    useEffect(() => {
        if (SelectedVersionID > -1) {
            GetVersion(SelectedVersionID, (version) => {
                setSelectedVersion(version)
            })
        }
    }, [SelectedVersionID])

    function GetSelectedProductVersions() {
        GetProductVersions(Products[props.SelectedIndex].id, (result) => {
            setVersions(result)
            if (result[0]) setSelectedVersionID(result[0].id)
        })
    }

    function onAddNewPriceStep(newVersion) {
        AddVersion(newVersion, (version) => {
            let UpdatedVersions = [...Versions]
            UpdatedVersions.push(version)
            UpdatedVersions.sort((a, b) => a.ApplicableDate < b.ApplicableDate)
            setVersions(UpdatedVersions)
            setOpenPriceStepPanel(false)
        })
    }

    function onExport() {
        let csvContent = 'data:text/csv;charset=utf-8,,'
        const { Options } = SelectedVersion
        Options[0].Prices.forEach((p) => {
            csvContent = csvContent + ',' + p.Size
        })
        csvContent = csvContent + '\n'
        Options.forEach((opt) => {
            csvContent = csvContent + opt.id + ',' + (opt.Number ? opt.Number : '') + ' ' + opt.Name.replace(',', ' ')
            opt.Prices.forEach((p) => {
                if (p.Compatible) csvContent = csvContent + ',' + (p.Price !== null ? p.Price : '')
                else csvContent = csvContent + ',NA'
            })
            csvContent = csvContent + '\n'
        })

        var encodedUri = encodeURI(csvContent)
        var link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', 'my_data.csv')
        document.body.appendChild(link)
        link.click()
    }

    return (
        <div className='TabsContainer'>
            <div className='TabsHeader'>
                {Products.length > 1 &&
                    Products.map((product, index) => (
                        <div
                            key={product.id}
                            onClick={() => props.OnSelectedProductChange(index)}
                            className={'Tab ' + (props.SelectedIndex === index ? 'SelectedTab' : '')}>
                            <span>{product?.Description}</span>
                        </div>
                    ))}
            </div>
            <div className='SelectedTabContainer'>
                {Products[0].Name !== 'Boss' && (
                    <div>
                        <h2>Actions</h2>
                        <div>
                            <span onClick={onExport} className='PrimaryButton'>
                                Export to CSV
                            </span>
                            <span onClick={props.onDeleteProduct} className='SecondaryWarningButton'>
                                Delete Product
                            </span>
                        </div>
                    </div>
                )}
                {SelectedVersion && SelectedVersion.Options ? (
                    <div>
                        <h2>List Prices</h2>
                        <CustomTable
                            SelectedVersion={SelectedVersion}
                            onSelectedVersionUpdate={(version) => setSelectedVersion(version)}
                        />
                    </div>
                ) : null}
            </div>
            <SidePanel Header='Add Price Step' IsOpen={OpenPriceStepPanel} onClose={() => setOpenPriceStepPanel(false)}>
                <AddPriceStepForm
                    Versions={Versions}
                    CurrentVersionID={SelectedVersionID}
                    onAddNewPriceStep={onAddNewPriceStep}
                />
            </SidePanel>
        </div>
    )
}

export default ProductTabs
