import Constants from '../constants'
import { carrierAppsHubApiUrl, uiUrl } from '../../environments/environments'

function GetUserPermissions(callback) {
    console.log('GetUsersPermissions:', carrierAppsHubApiUrl)
    fetch(`${carrierAppsHubApiUrl}/users/GetUserProfileWithAccessPermissions?appurl=${uiUrl}`, {
        method: 'GET',
        headers: Constants.ApiHeader(),
    })
        .then((res) => res.json())
        .then((json) => {
            callback(json)
        })
}

export { GetUserPermissions }
