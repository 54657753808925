import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ChillersComponent from './components/chillersComponent/chillersComponent'
import UnitsPage from './components/rooftopsComponent/unitsComponent/unitsPage'
import WheelsPage from './components/rooftopsComponent/wheelsComponent/wheelsPage'
import AirFlowsPage from './components/rooftopsComponent/airflowsComponent/airflowsPage'
import FansPage from './components/rooftopsComponent/fansComponent/fansPage'
import RooftopsOptionsPage from './components/rooftopsComponent/rooftopsOptionsComponent/rooftopsOptionsPage'
import HomePage from './components/homePage/homePage'
import ControlComponent from './components/chillersComponent/controlComponent'

function App() {
    return (
        <Router>
            <Route path='/' component={HomePage} />
            <Switch>
                <Route path='/rooftops/units' component={UnitsPage} />
                <Route path='/rooftops/wheels' component={WheelsPage} />
                <Route path='/rooftops/airFlows' component={AirFlowsPage} />
                <Route path='/rooftops/fans' component={FansPage} />
                <Route path='/rooftops/options' component={RooftopsOptionsPage} />
                <Route path='/chillers' component={ChillersComponent} />
                <Route path='/rooftops' component={UnitsPage} />
                <Route path='/control' component={ControlComponent} />
            </Switch>
        </Router>
    )
}
export default App
