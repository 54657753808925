let Constants = class {

    static ApiHeader() {
    return new Headers({
      'Content-Type': 'application/json;charset=utf-8',
      'Authorization': 'Bearer ' + localStorage.getItem('msal.idtoken')
    }) 
  }
  
}
export default Constants