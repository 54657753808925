import Constants from '../constants'
import { apiUrl } from '../../environments/environments'

function GetVersion(VersionID, callback) {
    fetch(apiUrl + '/Versions/' + VersionID, {
        method: 'GET',
        headers: Constants.ApiHeader(),
    })
        .then((res) => res.json())
        .then((json) => {
            callback(json.result)
        })
}

function DeleteVersion(VersionID, callback) {
    fetch(apiUrl + '/Versions/' + VersionID, {
        method: 'DELETE',
        headers: Constants.ApiHeader(),
    })
        .then((res) => res.json())
        .then((json) => {
            callback(json.result)
        })
}

function UpdateVersion(Version, callback) {
    fetch(apiUrl + '/Versions/' + Version.id, {
        method: 'PUT',
        body: JSON.stringify(Version),
        headers: Constants.ApiHeader(),
    })
        .then((res) => res.json())
        .then((json) => {
            callback(json.result)
        })
}

function AddVersion(Version, callback) {
    fetch(apiUrl + '/Versions', {
        method: 'POST',
        body: JSON.stringify(Version),
        headers: Constants.ApiHeader(),
    })
        .then((res) => res.json())
        .then((json) => {
            callback(json.result)
        })
}

export { GetVersion, UpdateVersion, AddVersion, DeleteVersion }
