import React, {useState, useEffect} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function AddPriceStepForm(props) {
    const [NewVersion, setNewVersion] = useState()
    const [referenceVersionIndex, setReferenceVersionIndex] = useState(0)

    useEffect(() => {
        if(props.CurrentVersionID){
            let defaultVersion = props.Versions.find(ver => ver.id === props.CurrentVersionID)
            if(defaultVersion){
                defaultVersion.BasedOnVersionID = defaultVersion.id
                defaultVersion.minDate = defaultVersion.ApplicableDate
                setNewVersion(defaultVersion)
            }
            
        }
        

    },[props.Versions, props.CurrentVersionID])

    function onSelectedReferenceChange(e){
        setReferenceVersionIndex(e.target.value)
        let version = props.Versions[e.target.value]
        version.BasedOnVersionID = props.Versions[e.target.value].id
        setNewVersion(version)
    }

    function onVersionApplicableDateChange(date) {
        let UpdatedVersion = {...NewVersion}
        UpdatedVersion.ApplicableDate = date
        setNewVersion(UpdatedVersion)
        
      }

    return(
        props.Versions.length >0?
        <div>
            <div className="SidePanelContentContainer">
                <div className="SidePanelLabel">Select the reference step</div>
                <select value={referenceVersionIndex} onChange={onSelectedReferenceChange}>
                    {props.Versions.map((version, index) => (
                    <option key={index} value={index}>{version.ApplicableDate}</option>
                    ))}
                </select>
                <div className="SidePanelLabel">Select the step applicable date</div>
                {NewVersion?
                <DatePicker 
                    minDate={new Date(NewVersion.minDate)}
                    selected={new Date(NewVersion.ApplicableDate)}
                    onChange={(date) => onVersionApplicableDateChange(date)}
                    inline showWeekNumbers 
                />:null}
            </div>
            <div className="MainPanelButtonContainer">
                <div onClick={() => props.onAddNewPriceStep(NewVersion)} className="MainPanelButton">Add Price Step</div>
            </div>
        </div>
        :null
    )
}

export default AddPriceStepForm
