import React from 'react'
import { UpdatePrice } from '../../../assets/interfaces/priceInterface'
import './customTable.css'

function CustomTable(props) {
    function onPriceChange(Price, event) {
        let value = event.target.value.trim()
        if (!isNaN(value) || value === '-' || value === '.') {
            let UpdatedVersionOptions = { ...props.SelectedVersion }
            let OptionIndex = UpdatedVersionOptions.Options.findIndex((opt) => opt.id === Price.OptionID)
            let PriceIndex = UpdatedVersionOptions.Options[OptionIndex].Prices.findIndex((p) => p.Size === Price.Size)
            if (!UpdatedVersionOptions.Options[OptionIndex].Prices[PriceIndex].OldPrice)
                UpdatedVersionOptions.Options[OptionIndex].Prices[PriceIndex].OldPrice =
                    UpdatedVersionOptions.Options[OptionIndex].Prices[PriceIndex].Price
            UpdatedVersionOptions.Options[OptionIndex].Prices[PriceIndex].Price = value
            props.onSelectedVersionUpdate(UpdatedVersionOptions)
        }
    }

    function onInputFocusOut(Price) {
        if (Price.OldPrice !== undefined && Price.OldPrice !== Price.Price) {
            UpdatePrice(Price, (result) => {
                if (result === true) {
                    let UpdatedVersionOptions = { ...props.SelectedVersion }
                    let OptionIndex = UpdatedVersionOptions.Options.findIndex((opt) => opt.id === Price.OptionID)
                    let PriceIndex = UpdatedVersionOptions.Options[OptionIndex].Prices.findIndex(
                        (p) => p.Size === Price.Size
                    )
                    UpdatedVersionOptions.Options[OptionIndex].Prices[PriceIndex].OldPrice = undefined
                    props.onSelectedVersionUpdate(UpdatedVersionOptions)
                }
            })
        }
    }

    return (
        <div className='PriceTableContainer'>
            <table
                className='PricesTable'
                style={{ width: props.SelectedVersion.Options[0].Prices.length * 80 + 'px' }}>
                <tbody>
                    <tr>
                        <th></th>
                        {props.SelectedVersion.Options[0].Prices.map((price) => (
                            <th key={price.id} className='TableHeader'>
                                {price.Size}
                            </th>
                        ))}
                    </tr>
                    {props.SelectedVersion.Options.map((Opt) => (
                        <tr key={Opt.id}>
                            <td className='TableRowHeader'>
                                <span className='OptNumberCellSpan'>{Opt.Number}</span>
                                <span className='OptNameCellSpan'>{Opt.Name}</span>
                            </td>
                            {Opt.Prices.map((Price) =>
                                Price.Compatible ? (
                                    <td key={Price.id} className={'PriceCell ' + (Price.Price ? '' : 'EmptyPriceCell')}>
                                        <input
                                            onChange={(event) => onPriceChange(Price, event)}
                                            onBlur={() => onInputFocusOut(Price)}
                                            className='PriceCellInput'
                                            value={Price.Price ? Price.Price : ''}
                                        />
                                        <span className='PriceCellCurrency'> €</span>
                                    </td>
                                ) : (
                                    <td key={Price.id} className='NotCompatiblePriceCell'>
                                        NC
                                    </td>
                                )
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default CustomTable
