import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import './sidePanel.css';

function SidePanel(props) {

  return(
    <div>
        <div className={"SidePanelContainer " + (props.IsOpen?"SPOpen":"SPClose")}>
            <div className="SidePanelHeader">
                <span>{props.Header}</span>
                <svg className="SidePanelCloseIcon" onClick={props.onClose} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 348.333 348.334">
                    <g>
                        <path d="M336.559,68.611L231.016,174.165l105.543,105.549c15.699,15.705,15.699,41.145,0,56.85c-7.844,7.844-18.128,11.769-28.407,11.769c-10.296,0-20.581-3.919-28.419-11.769L174.167,231.003L68.609,336.563c-7.843,7.844-18.128,11.769-28.416,11.769c-10.285,0-20.563-3.919-28.413-11.769c-15.699-15.698-15.699-41.139,0-56.85l105.54-105.549L11.774,68.611c-15.699-15.699-15.699-41.145,0-56.844c15.696-15.687,41.127-15.687,56.829,0l105.563,105.554L279.721,11.767c15.705-15.687,41.139-15.687,56.832,0C352.258,27.466,352.258,52.912,336.559,68.611z"/>
                    </g>
                </svg>
            </div>
            {props.IsOpen?props.children:null}
        </div>
        {props.IsOpen?
        <div onClick={props.onClose} className="SidePanelHider"/>
        :null}
    </div>
    
  )

}

export default SidePanel
