import Constants from '../constants'
import { apiUrl } from '../../environments/environments'

function UpdateControl(opt, callback) {
    fetch(apiUrl + '/control/' + opt.id, {
        method: 'PUT',
        body: JSON.stringify(opt),
        headers: Constants.ApiHeader(),
    })
        .then((res) => res.json())
        .then((json) => {
            if (callback) callback(json.result)
        })
}

export { UpdateControl }
