import React, { useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'

const StepUploadFile = ({ onFileUpload, currentStep, setCurrentStep, errors, status }) => {
    const [displayError, setDisplayError] = useState(false)

    const handleFileUpload = (event) => {
        const uploadedFile = event.target.files[0]
        onFileUpload(uploadedFile)
    }

    useEffect(() => {
        if (status === 'finished') {
            if (errors.length > 0) {
                setDisplayError(true)
            } else {
                setCurrentStep(currentStep + 1)
            }
        }
    }, [status, errors])

    return (
        <TableContainer>
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell align='center'>
                            <Typography variant='h5' component='h2'>
                                Upload Excel File
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component='th' scope='row'>
                            <input type='file' accept='.xlsx' onChange={handleFileUpload} />
                        </TableCell>
                    </TableRow>
                    {displayError && errors.map((error) => <TableRow>{error}</TableRow>)}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default StepUploadFile
