export const filterDataByBrand = (data, brand) => {
        return data.filter((d) => {
           if (brand === 'Carrier' && d.Code_Modele && d.Code_Modele.includes('50F')) {
                return true        
            }
           if (brand === 'Ciat' && d.Code_Modele && d.Code_Modele.includes('PJ')) {
                return true        
            }
            return brand === 'All'
        })
}
