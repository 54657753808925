import React, { useEffect, useState } from 'react'
import {
    Box,
    Button,
    CircularProgress,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core'
import { GetProductVarconds, UpdateProducts } from '../../../../assets/interfaces/productInterface'
import ImportIcon from '@material-ui/icons/CloudUpload'
import { UpdatePrices } from '../../../../assets/interfaces/priceInterface'

const StepCompareVarconds = ({ validatedProducts, products }) => {
    const [prices, setPrices] = useState([])
    const [excelProducts, setExcelProducts] = useState([])
    const priceIdSet = new Set()
    const [loading, setLoading] = useState(false)
    const [resultMessage, setResultMessage] = useState(null)

    const handleUpdateVarcondsClick = () => {
        setLoading(true)

        const filteredVarconds = prices
            .filter((price) => {
                if (price.varcond && price.priceId && price.varcondDb !== price.varcond) {
                    if (!priceIdSet.has(price.priceId)) {
                        priceIdSet.add(price.priceId)
                        return true
                    }
                }
                return false
            })
            .map((price) => {
                return { id: price.priceId, Varcond: price.varcond }
            })

        const filteredProducts = excelProducts.map(({ name, kmat }) => ({ productName: name, kmat }))

        UpdateProducts(filteredProducts)
            .then((updatedVarconds) => {
                setResultMessage('Products updated successfully')
            })
            .catch((error) => {
                setResultMessage(`Error updating Products: ${error}`)
            })
            .finally(() => {
                setLoading(false)
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            })

        UpdatePrices(filteredVarconds)
            .then((updatedVarconds) => {
                setResultMessage('Prices updated successfully')
            })
            .catch((error) => {
                setResultMessage(`Error updating prices: ${error}`)
            })
            .finally(() => {
                setLoading(false)
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            })
    }

    const filteredProducts = (async () => {
        const uniqueProductNames = Array.from(
            new Set(
                products
                    .filter(({ productName }) => validatedProducts.includes(productName))
                    .map(({ productName }) => productName)
            )
        )

        const productVarcondsMap = new Map()
        await Promise.all(
            uniqueProductNames.map(async (productName) => {
                const varconds = await GetProductVarconds(productName)
                productVarcondsMap.set(productName, varconds)
            })
        )

        const filteredArray = await Promise.all(
            products
                .filter(
                    ({ type, productName }) =>
                        (type === 'Base unit' || type === 'Option' || type === 'Packing') &&
                        validatedProducts.includes(productName)
                )
                .map(async ({ productName, size, varcond, type, optionNb, kmat }, index) => {
                    const productVarconds = productVarcondsMap.get(productName)

                    const priceDb = productVarconds?.find((price) => {
                        if (price.Size && (type === 'Base unit' || price.Number)) {
                            return (
                                price.Size.toString() === size.toString() &&
                                (type === 'Base unit' || optionNb.toString() === price.Number.toString())
                            )
                        }
                        return false
                    })

                    return {
                        name: productName,
                        size,
                        kmat,
                        type,
                        varcond,
                        optionNb,
                        varcondDb: priceDb ? priceDb.Varcond : null,
                        priceId: priceDb ? priceDb.id : null,
                        isCompatible: priceDb ? priceDb.Compatible : false,
                    }
                })
        )

        return filteredArray
    })()

    useEffect(() => {
        filteredProducts.then((result) => {
            const uniquePrices = new Set()
            const uniqueProducts = new Set()

            const filteredProducts = result.filter((price) => {
                if (!uniqueProducts.has(price.name) && price.isCompatible === true) {
                    uniqueProducts.add(price.name)
                    return true
                }
                return false
            })

            const filteredResult = result.filter((price) => {
                if (
                    !uniquePrices.has(price.priceId) &&
                    price.varcondDb !== price.varcond &&
                    price.isCompatible === true
                ) {
                    uniquePrices.add(price.priceId)
                    return true
                }
                return false
            })

            const filteredResultArray = Array.from(filteredResult)
            const filteredProductsArray = Array.from(filteredProducts)

            setPrices(filteredResultArray)
            setExcelProducts(filteredProductsArray)
        })
    }, [])

    function calculateVarcondDifference(nameFilter) {
        const filteredItems = prices && prices.filter((item) => item.name === nameFilter)

        const count = filteredItems.reduce((acc, item) => {
            if (item.varcond && item.varcondDb !== item.varcond) {
                return acc + 1
            }
            return acc
        }, 0)

        return count
    }

    return (
        <>
            <TableContainer style={{ maxHeight: '500px', overflowX: 'auto', display: 'flex', flexDirection: 'column' }}>
                <Box style={{ flex: '1' }}>
                    <Typography variant='h6' component='h2'>
                        Varconds of the following machine{validatedProducts.length > 1 && 's'} will be updated
                    </Typography>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant='h6' component='h2'>
                                        Product Name{validatedProducts.length > 1 && 's'}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant='h6' component='h2'>
                                        Status in the database
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {validatedProducts.map((product, index) => (
                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component='th' scope='row'>
                                        <Typography variant='h6' component='h2'>
                                            {product}
                                        </Typography>
                                    </TableCell>
                                    <TableCell component='th' scope='row'>
                                        <Typography variant='h6' component='h2'>
                                            {calculateVarcondDifference(product)} Varcond
                                            {calculateVarcondDifference(product) > 1 && 's'} will be updated
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Snackbar
                        open={!!resultMessage}
                        autoHideDuration={3000}
                        onClose={() => setResultMessage(null)}
                        message={resultMessage}
                    />
                </Box>

                <Box display='flex' justifyContent='center' m={2}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Button
                            onClick={handleUpdateVarcondsClick}
                            component='label'
                            color='primary'
                            variant='outlined'
                            size='large'
                            startIcon={<ImportIcon />}>
                            Update varconds
                        </Button>
                    )}
                </Box>
            </TableContainer>
        </>
    )
}

export default StepCompareVarconds
