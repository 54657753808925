import React from 'react'
import XLSX from 'xlsx'
import { Button } from '@material-ui/core'
import ExportIcon from '@material-ui/icons/CloudDownload'


function ExportButton({ data , fileName, sheetName}) {

    const exportExcel = () => {
        data.map((row) => {
            delete row.tableData
            delete row.id
            return row
        })
        const workSheet = XLSX.utils.json_to_sheet(data)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, workSheet, sheetName)
        //Binary string
        XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' })
        //Download
        XLSX.writeFile(workbook, fileName)
    }

    return (
        <Button onClick={exportExcel} color='primary' size='large' variant='outlined' startIcon={<ExportIcon />}>
            Download current prices
        </Button>
    )
}
export default ExportButton
