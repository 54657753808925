import React, { useState, useContext } from 'react'
import './header.css'
import { CarrierLogo, UserIcon, CloseIcon } from './svgIcons'
import { logout } from '@carrier/auth-module'
import appConfig from '../../environments/environments'
import { NavLink } from 'react-router-dom'
import { UserContext } from '../../context/userContext'
import packageJson from '../../../package.json'

function Header() {
    const [OpenAccount, setOpenAccount] = useState(false)
    const [state] = useContext(UserContext)
    return (
        <header className='App-header'>
            <NavLink to='/'>
                <CarrierLogo className='Header-Logo' />
            </NavLink>
            <div
                className={'Header-AccountBtn ' + (OpenAccount ? 'Header-AccountBtnOpen' : '')}
                onClick={() => setOpenAccount(!OpenAccount)}>
                <UserIcon className='Header-userIcon' />
            </div>
            {OpenAccount ? (
                <div className='AccountCard'>
                    <div className='AccountCard-Header'>
                        <span>PriceManagement {packageJson.version}</span>
                        <span onClick={() => setOpenAccount(!OpenAccount)}>
                            <CloseIcon className='CloseIcon' />
                        </span>
                    </div>
                    <span className='AccountCard-UserName'>{state.UserName}</span>
                    <span
                        className='PrimaryButton HeaderButton'
                        onClick={() => {
                            logout(appConfig)
                        }}>
                        Logout
                    </span>
                </div>
            ) : null}
        </header>
    )
}

export default Header
