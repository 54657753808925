import React, { useEffect, useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core'
import { GetProductVarconds } from '../../../assets/interfaces/productInterface'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import SubjectIcon from '@material-ui/icons/Subject'
import CloudDoneIcon from '@material-ui/icons/CloudDone'

const ProductStatus = ({ name, products, validatedProducts, setValidatedProducts }) => {
    const StatusEnum = {
        LOADING: 'Loading',
        READY: 'Ready',
        MISSING: 'Missing',
        ERROR: 'Error',
    }

    const [status, setStatus] = useState(StatusEnum.LOADING)
    const [statusBySize, setStatusBySize] = useState([])

    const fetchData = async (name) => {
        try {
            const result = await GetProductVarconds(name)

            const productSizes = [
                ...new Set(
                    products
                        .filter(({ productName, type }) => productName === name && type !== 'Header')
                        .map(({ size }) => {
                            return size
                        })
                ),
            ]
            const statusBySize = productSizes.map((productSize) => {
                const excelOptions = [
                    ...new Set(
                        products
                            .filter(
                                ({ type, productName, size }) =>
                                    (type === 'Packing' || type === 'Option') &&
                                    productName === name &&
                                    size.toString() === productSize.toString()
                            )
                            .map(({ optionNb }) => {
                                return optionNb.toString()
                            })
                    ),
                ]

                const dbOptions = result
                    .filter(
                        (price) =>
                            price.hasOwnProperty('Number') &&
                            price.Size.toString() === productSize.toString() &&
                            price.Compatible === true
                    )
                    .map(({ Number }) => Number.toString())

                const notExistInDb = excelOptions.filter((option) => !dbOptions.includes(option))
                const notExistInExcel = dbOptions.filter((option) => !excelOptions.includes(option))

                if (notExistInDb.length === 0 && notExistInExcel.length === 0) {
                    return { size: productSize, status: StatusEnum.READY, notExistInExcel }
                } else if (notExistInDb.length === 0 && notExistInExcel.length > 0) {
                    return { size: productSize, status: StatusEnum.MISSING, notExistInExcel }
                } else return { size: productSize, status: StatusEnum.ERROR, notExistInDb, notExistInExcel }
            })

            return { statusBySize }
        } catch (error) {
            throw error
        }
    }

    const processStatusBySize = (statusBySize) => {
        if (statusBySize.every((item) => item.status === StatusEnum.READY)) {
            return StatusEnum.READY
        } else if (statusBySize.some((item) => item.status === StatusEnum.ERROR)) {
            return StatusEnum.ERROR
        } else if (statusBySize.some((item) => item.status === StatusEnum.MISSING)) {
            return StatusEnum.MISSING
        }
        return StatusEnum.ERROR
    }

    const isProductValidated = validatedProducts.includes(name)

    useEffect(() => {
        fetchData(name)
            .then(({ statusBySize }) => {
                const status = processStatusBySize(statusBySize)
                setStatus(status)
                setStatusBySize(statusBySize)
            })
            .catch((error) => {
                setStatus(StatusEnum.ERROR)
            })
    }, [])

    useEffect(() => {
        if ((status === StatusEnum.READY || status === StatusEnum.MISSING) && !isProductValidated) {
            setValidatedProducts((prev) => [...prev, name])
        }
    }, [status, isProductValidated, setValidatedProducts, name])

    const renderStatusIcon = () => {
        switch (status) {
            case StatusEnum.LOADING:
                return (
                    <div>
                        <HourglassEmptyIcon />
                        Loading...
                    </div>
                )
            case StatusEnum.READY:
                return (
                    <div style={{ color: 'green' }}>
                        <CheckCircleIcon /> All The varconds of the machine are ready to be updated.
                    </div>
                )
            case StatusEnum.MISSING:
                return (
                    <div style={{ color: 'orange' }}>
                        <ErrorIcon /> This machine has some issues with certain options , ready to be updated.
                    </div>
                )
            case StatusEnum.ERROR:
                return (
                    <div style={{ color: 'red' }}>
                        <ErrorIcon /> The varconds of the machine can't be updated.
                    </div>
                )
            default:
                return null
        }
    }

    return (
        <Accordion>
            <AccordionSummary>
                <Typography>{renderStatusIcon()}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {status !== StatusEnum.ERROR || (status === StatusEnum.ERROR && statusBySize.length > 0) ? (
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography style={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}>
                                <CloudDoneIcon style={{ marginRight: '6px', color: 'blue' }} /> Missing options in
                                database:
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Machine Size</TableCell>
                                            <TableCell>Options</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {statusBySize
                                            .filter((size) => size.notExistInDb && size.notExistInDb.length > 0)
                                            .map((status, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{status.size}</TableCell>
                                                    <TableCell
                                                        style={{
                                                            maxWidth: '200px',
                                                            whiteSpace: 'pre-wrap',
                                                            wordWrap: 'break-word',
                                                            padding: '0',
                                                        }}>
                                                        {status.notExistInDb.join(',')}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        {status !== StatusEnum.ERROR && (
                            <Grid item xs={6}>
                                <Typography style={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}>
                                    <SubjectIcon style={{ marginRight: '6px', color: 'blue' }} /> Missing Options in the
                                    Uploaded Excel:
                                </Typography>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Machine Size</TableCell>
                                                <TableCell>Options</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {statusBySize
                                                .filter(
                                                    (size) => size.notExistInExcel && size.notExistInExcel.length > 0
                                                )
                                                .map((status, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{status.size}</TableCell>
                                                        <TableCell
                                                            style={{
                                                                maxWidth: '200px',
                                                                whiteSpace: 'pre-wrap',
                                                                wordWrap: 'break-word',
                                                                padding: '0',
                                                            }}>
                                                            {status.notExistInExcel.join(',')}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    'Incorrect inputs, machine and/or options mismatch, please verify the excel data'
                )}
            </AccordionDetails>
        </Accordion>
    )
}

export default ProductStatus
