import React, { useState, useEffect } from 'react'
import ProductTabs from '../chillersComponent/productTabs/productTabs'
import { GetProducts, DeleteProduct } from '../../assets/interfaces/productInterface'
import { GetUserPermissions } from '../../assets/interfaces/uMInterface'
import './chillersComponent.css'
import { Button, Modal, Box } from '@material-ui/core'
import ImportIcon from '@material-ui/icons/CloudUpload'
import PricesUpdate from './pricesUpdate/pricesUpdate'
function ChillersComponent() {
    const [Products, setProducts] = useState([])
    const [SelectedIndex, setSelectedIndex] = useState()
    const [IsUserAllowed] = useState(true)
    useEffect(() => {
        GetUserPermissions((result) => {
            if (result) {
                //setIsUserAllowed(result.appAccess)
            }
        })
        UpdateProductList()
    }, [])

    function DeleteCurrentProduct() {
        DeleteProduct(Products[SelectedIndex].id, (result) => UpdateProductList())
    }

    function UpdateProductList() {
        GetProducts(null, (UpdatedProducts) => {
            setProducts(UpdatedProducts)
            setSelectedIndex(0)
        })
    }
    const [isModalOpen, setIsModalOpen] = useState(false)

    return (
        <div className='AppContent'>
            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} disableBackdropClick={true}>
                <PricesUpdate setIsModalOpen={setIsModalOpen} />
            </Modal>
            {IsUserAllowed ? (
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'row', m: 2 }}>
                        {' '}
                        <h1>Prices</h1>
                        <Button
                            onClick={() => setIsModalOpen(true)}
                            component='label'
                            color='primary'
                            variant='contained'
                            size='large'
                            startIcon={<ImportIcon />}>
                            Upload new prices
                        </Button>
                    </Box>

                    {Products.length > 0 ? (
                        <ProductTabs
                            Products={Products}
                            SelectedIndex={SelectedIndex}
                            onDeleteProduct={DeleteCurrentProduct}
                            OnSelectedProductChange={(ProdIndex) => setSelectedIndex(ProdIndex)}
                        />
                    ) : (
                        <h2>No products are available</h2>
                    )}
                </>
            ) : (
                <h1>You are not authorized to see this content</h1>
            )}
        </div>
    )
}

export default ChillersComponent
