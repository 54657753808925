import React from 'react'
import { Tabs, Tab } from '@material-ui/core'
import { NavLink, useLocation } from 'react-router-dom'
import './menu.css'

const Menu = () => {
    const { pathname } = useLocation()
    const PATH = {
        UNITS: '/rooftops/units',
        WHEELS: '/rooftops/wheels',
        AIRFLOWS: '/rooftops/airFlows',
        FANS: '/rooftops/fans',
        OPTIONS: '/rooftops/options',

    }
    return (
        <Tabs className='rooftop'>
            <NavLink to={PATH.UNITS} className='links'>
                <Tab className={pathname === PATH.UNITS || pathname === '/rooftops' ? 'tab current' : 'tab'} label='Units' />
            </NavLink>
            <NavLink to={PATH.WHEELS} className="links">
                <Tab className={pathname === PATH.WHEELS ? 'tab current' : 'tab'} label='Wheels' />
            </NavLink>
            <NavLink to={PATH.AIRFLOWS} className="links">
                <Tab className={pathname === PATH.AIRFLOWS ? 'tab current' : 'tab'} label='AirFlows' />
            </NavLink>
            <NavLink to={PATH.FANS} className="links">
                <Tab className={pathname === PATH.FANS ? 'tab current' : 'tab'} label='Fans' />
            </NavLink>
            <NavLink to={PATH.OPTIONS} className="links">
                <Tab className={pathname === PATH.OPTIONS ? 'tab current' : 'tab'} label='OPTIONS' />
            </NavLink>
        </Tabs>
    )
}

export default Menu
