import React from 'react'
import XLSX from 'xlsx'
import { Button } from '@material-ui/core'
import ImportIcon from '@material-ui/icons/CloudUpload'
import { ApiService } from '@carrier/workflowui-globalfunctions'
import { apiUrl } from '../../../environments/environments'

const EXTENTIONS = ['xlsx', 'xls', 'csv']

function ImportButton({ api, onSuccess, onError, onStart }) {
    const getExtension = (file) => {
        const parts = file.name.split('.')
        const extention = parts[parts.length - 1]
        return EXTENTIONS.includes(extention)
    }

    const importExcel = (e) => {
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.onload = (event) => {
            onStart()
            //parse data
            const bstr = event.target.result
            const workBook = XLSX.read(bstr, { type: 'binary' })
            // get first sheet
            const workSheetName = workBook.SheetNames[0]
            const workSheet = workBook.Sheets[workSheetName]
            // convert to array
            const fileData = XLSX.utils.sheet_to_json(workSheet, {
                header: 1,
                blankrows: false,
            })
            // removing header
            fileData.splice(0, 1)
            //post data to database
            ApiService(`${apiUrl}${api}`, 'post', fileData)
                .then(({ status, data }) => {
                    if (status === 200 && data) {
                        onSuccess(data)
                    }
                })
                .catch((e) => {
                    console.log(e.response.data.message)
                    onError(e.response?.data?.message ?? e.message)
                })
        }

        if (file) {
            if (getExtension(file)) {
                reader.readAsBinaryString(file)
            } else {
                alert('Invalid file input, Select Excel, CSV file')
            }
        }
    }

    return (
        <>
            <Button component='label' color='primary' variant='contained' size='large' startIcon={<ImportIcon />}>
                Upload new prices
                <input type='file' hidden onChange={importExcel} />
            </Button>
        </>
    )
}
export default ImportButton
